import React from 'react'
import "./GapPage.css"

export default function GapPage() {
  return (
    <div className='touch'>
      <a href='#contact'>GET IN TOUCH</a>
    </div>
  )
}
